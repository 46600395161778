import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.8rem;
  flex-wrap: wrap;

  .nav-logo {
    max-width: 275px;
  }
`;

const NavItem = styled.span`
  display: flex;
  gap: 1rem;
`;

const Navlink = styled(NavLink)`
  text-decoration: none;
  text-transform: uppercase;
`;

export {
  Nav,
  NavItem,
  Navlink,
};
