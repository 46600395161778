import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormLabel,
  Typography,
} from '@mui/material';
import { startCase } from 'lodash';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  CardActionFooter,
  CardContainer,
  CardHead,
  CardTopbar,
  CardWrapper,
  CreatedAt,
} from '../../../style/components/card.styled';
import ApplicationMenuBar from './MenuBar';
import AvatarFnmLnm from '../../../func/AvatarContent';
import { MyContext } from '../../context/context';
import ApplicationListSkeleton from './statistics/ApplicationListSkeleton';
import NoDataSVGFile from '../../../img/svg/NoDataSVGFile';

const applicationStatusHandler = (props) => {
  if (props === 'created') {
    return (
      <Chip icon={<FactCheckOutlinedIcon />} label={props.toUpperCase()} />
    );
  }
  if (props === 'draft') {
    return <Chip icon={<PendingOutlinedIcon />} label={props} />;
  }

  if (props === 'started') {
    return <Chip icon={<StartOutlinedIcon />} label={props} />;
  }

  if (props === 'submitted') {
    return <Chip icon={<FactCheckOutlinedIcon />} label={props} />;
  }

  if (props === 'approved') {
    return <Chip icon={<VerifiedIcon />} label={props} />;
  }

  if (props === 'rejected') {
    return <Chip icon={<CancelOutlinedIcon />} label={props} />;
  }

  if (props === 'archived') {
    return <Chip icon={<ArchiveOutlinedIcon />} label={props} />;
  }
  if (props === 'restored') {
    return <Chip icon={<RestoreIcon />} label={props.toUpperCase()} />;
  }
  if (props === 'delivered') {
    return <Chip icon={<DoneAllIcon />} label={props.toUpperCase()} />;
  }
  return null;
};

// const d = 'createdBy=64b6641bf174b919e00712d6';

const ApplicationList = () => {
  const {
    viewCandidateApp,
    applicationLists,
    loading,
    getApplicationListFunc,
    // appFilterState,
    appFilterStrState,
  } = useContext(MyContext);

  const [resolutionController, setResolutionController] = useState(
    window.innerWidth,
  );

  useEffect(() => {
    getApplicationListFunc(appFilterStrState);
    const resolutionRecorder = () => {
      setResolutionController(window.innerWidth);
    };

    /* Adding Event Listners for Scroll and Re-size */
    /* for Scroll-to-Top and Resolution Controll */
    window.addEventListener('resize', resolutionRecorder);

    /* Dumping/Cleaning Up all task at end of useEffect */
    /* Execution to avoid memory leak and Other issues */
    return () => {
      window.removeEventListener('resize', resolutionRecorder);
    };
  }, []);
  localStorage.clear();
  return (
    <section>
      <ApplicationMenuBar />
      <CardWrapper>
        {!loading ? (
          applicationLists?.map((item) => {
            const {
              applicationId,
              applicationTag,
              candidateId,
              firstname,
              lastname,
              applicationStatus,
              createdAt,
              active,
            } = item;
            return (
              <CardContainer
                key={applicationId}
                leftborder="true"
                status={
                  applicationStatus && !active ? 'archived' : applicationStatus
                }
                active={active.toString()}
              >
                <CardTopbar>
                  <CreatedAt>
                    <AccessTimeIcon />
                    <Typography>{createdAt}</Typography>
                  </CreatedAt>
                </CardTopbar>

                <CardHead
                  avatar={(
                    <Avatar>
                      {AvatarFnmLnm({
                        fnm: firstname,
                        lnm: lastname,
                      })}
                    </Avatar>
                  )}
                  title={startCase(`${firstname}  ${lastname}`)}
                  subheader={applicationTag}
                />
                <Divider />
                <CardActionFooter>
                  <>
                    {applicationStatusHandler(
                      applicationStatus && !active
                        ? 'archived'
                        : applicationStatus,
                    )}
                    <Button
                      variant="text"
                      onClick={() => viewCandidateApp({
                        applicationId,
                        candidateId,
                        applicationStatus,
                        active,
                      })}
                    >
                      View
                    </Button>
                  </>
                </CardActionFooter>
              </CardContainer>
            );
          })
        ) : (
          <ApplicationListSkeleton
            resolutionController={resolutionController}
          />
        )}
      </CardWrapper>
      {!loading && applicationLists.length <= 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <FormLabel
            sx={{
              color: 'error.main',
              fontWeight: '400',
              fontSize: '24px',
            }}
          >
            No Associated Data
          </FormLabel>
          <span>
            <NoDataSVGFile />
          </span>
        </div>
      )}
    </section>
  );
};

export default ApplicationList;
