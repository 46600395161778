import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash';
import {
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Typography,
  FormLabel,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Formik } from 'formik';
import {
  CreateAppForm,
  CreateAppItem,
  FormAction,
  FormFieldWrap,
  FormWrapper,
  Title,
} from './Application.style';
import CreateOnBoardApplicationValidation from './statistics/validation';
import createOnBoardApplicationFormInitialValues from './statistics/createOnBoardApplicationFormInitialValues';
import ApplicationMenuBar from './MenuBar';
import {
  CardActionFooter,
  CardContainer,
  CardHead,
  CardTopbar,
  CreatedAt,
} from '../../../style/components/card.styled';
import { MyContext } from '../../context/context';
import {
  createOnBoardAppApi,
  getAppApi,
  updateApplicationDetails,
} from '../../../apis/apis';
import TextField from '../../components/inputs/TextField.input';
import FlexedBox from '../../components/Other/FlexedBox';
import { Spineer } from '../../../style/components/Spinner.styled';
import showSnackbar from '../../components/Message/showSnackbar';

const ApplicationForm = () => {
  const {
    sendAppToCandidate,
    getRoleSelectOptions,
    spineersLoader,
    setSpineersLoader,
  } = useContext(MyContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formInitVal, setFormInitVal] = useState(
    createOnBoardApplicationFormInitialValues,
  );

  const [isApplicationCreated, setIsApplicationCreated] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [readOnlyForm, setReadOnlyForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleCreateOnBoardAppSubmit = async (props) => {
    const createApp = await createOnBoardAppApi(props);
    const { success, data, message } = createApp;
    if (success) {
      localStorage.setItem('createdApplication', JSON.stringify(data));
      setIsApplicationCreated(true);
      setShowForm(false);
      setReadOnlyForm(true);
      setFormInitVal(data);
    }
    if (!success) {
      showSnackbar(message, { variant: 'error' });
    }
  };

  const existApplicationData = JSON.parse(
    localStorage.getItem('createdApplication'),
  );

  const editCreatedApplication = () => {
    if (!isEmpty(existApplicationData)) setFormInitVal(existApplicationData);
    setIsEditing(true);
    setReadOnlyForm(false);
    setShowForm(true);
  };
  const cancelEdit = () => {
    setIsEditing(false);
    setShowForm(false);
  };

  const handleApplicationUpdateDetails = async (props) => {
    const updatedApplicationDetails = await updateApplicationDetails(props);
    const { success, message } = updatedApplicationDetails;
    if (success) {
      if (searchParams.get('appId')) {
        navigate(-1);
      }
      localStorage.setItem('createdApplication', JSON.stringify(props));
      setIsEditing(false);
      setShowForm(false);
    } else {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
    }
  };

  const applicationOprationsButton = (props) => {
    if (isEditing) {
      return (
        <>
          <Button
            type="button"
            variant="outlined"
            sx={{ mr: 1 }}
            onClick={cancelEdit}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleApplicationUpdateDetails(props)}
          >
            Update
          </Button>
        </>
      );
    }
    return searchParams.get('appId') ? (
      <Button
        variant="contained"
        disableElevation
        onClick={() => handleApplicationUpdateDetails(props)}
      >
        Update
      </Button>
    ) : (
      <Button variant="contained" disableElevation type="submit">
        Submit Application
      </Button>
    );
  };

  const isApplicationExistFun = () => {
    if (!isEmpty(existApplicationData)) {
      setIsApplicationCreated(true);
      setShowForm(false);
    }
  };

  const createdApplicationCard = () => {
    const {
      createdAt,
      firstname,
      lastname,
      applicationTag,
      applicationStatus,
    } = existApplicationData || formInitVal;
    return (
      <CardContainer>
        <CardTopbar>
          <CreatedAt>
            <AccessTimeIcon />
            <Typography>{createdAt}</Typography>
          </CreatedAt>
        </CardTopbar>
        <CardHead
          avatar={<Avatar>SD</Avatar>}
          title={`${firstname} ${lastname}`}
          subheader={applicationTag}
        />
        <Divider />
        <CardActionFooter>
          <Chip icon={<PendingOutlinedIcon />} label={applicationStatus} />
          <ButtonGroup variant="contained" disableElevation>
            {!showForm ? (
              <Button
                variant="contained"
                disableElevation
                onClick={editCreatedApplication}
                disabled={spineersLoader}
              >
                Edit
              </Button>
            ) : null}
            {/* <Button
              variant="contained"
              disableElevation
              onClick={() =>
                applicationDraft(
                  pick(existApplicationData || formInitVal, [
                    'applicationId',
                    'candidateId',
                  ])
                )
              }
            >
              Save as Draft
            </Button> */}
            <Button
              variant="contained"
              onClick={() => {
                setSpineersLoader(true);
                sendAppToCandidate(
                  pick(existApplicationData || formInitVal, [
                    'firstname',
                    'lastname',
                    'email',
                    'applicationId',
                    'candidateId',
                  ]),
                );
              }}
              disableElevation
              disabled={spineersLoader}
            >
              Send Application
              {spineersLoader && <Spineer />}
            </Button>
          </ButtonGroup>
          {/* <span><Spineer /></span> */}
        </CardActionFooter>
      </CardContainer>
    );
  };

  const getAppData = async () => {
    const appData = await getAppApi(searchParams.get('appId'));
    const { success, message } = appData;
    if (success) {
      localStorage.setItem('createdApplication', JSON.stringify(appData.data));
    } else {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
    }
  };

  useEffect(() => {
    if (searchParams.get('appId')) {
      getAppData();
      setIsApplicationCreated(false);
      setShowForm(true);
    } else {
      isApplicationExistFun();
      getRoleSelectOptions('name=2');
    }
  }, []);
  return (
    <section>
      <ApplicationMenuBar />
      {isApplicationCreated && createdApplicationCard()}
      {showForm && (
        <Formik
          initialValues={existApplicationData || formInitVal}
          validationSchema={CreateOnBoardApplicationValidation}
          onSubmit={(values) => {
            handleCreateOnBoardAppSubmit(values);
          }}
          enableReinitialize
        >
          {(props) => {
            const {
              values, touched, errors, handleChange,
            } = props;
            return (
              <div>
                {searchParams.get('appId') && (
                  <FlexedBox justifyContent="flex-end" alignItems="center">
                    <FormLabel fontWeight="fontWeightBold">Note:</FormLabel>
                    Its important to note that only applications in draft mode
                    are eligible for updates.
                  </FlexedBox>
                )}
                <CreateAppForm>
                  <FormWrapper>
                    <CreateAppItem>
                      <Title mb={2}>Candidate Details</Title>
                      <FormFieldWrap>
                        <TextField
                          variant="outlined"
                          id="applicationFirstNameId"
                          label="Firstname"
                          placeholder="Firstname"
                          name="firstname"
                          value={values.firstname}
                          onChange={handleChange}
                          errors={errors.firstname}
                          touched={touched.firstname}
                          disabled={readOnlyForm}
                        />
                        <TextField
                          variant="outlined"
                          id="applicationLastnameId"
                          label="Lastname"
                          name="lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          errors={errors.lastname}
                          touched={touched.lastname}
                          disabled={readOnlyForm}
                        />
                        <TextField
                          variant="outlined"
                          id="applicationEmailId"
                          type="email"
                          label="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          disabled={readOnlyForm}
                          touched={touched.email}
                          errors={errors.email}
                          className="col-1-span-2"
                        />
                        <TextField
                          variant="outlined"
                          id="applicationContactNoId"
                          type="number"
                          label="Contact No"
                          name="contactNo"
                          value={values.contactNo}
                          onChange={handleChange}
                          touched={touched.contactNo}
                          errors={errors.contactNo}
                          disabled={readOnlyForm}
                          className="col-1-span-2"
                        />
                      </FormFieldWrap>
                    </CreateAppItem>
                    {/* <Divider orientation="vertical" flexItem className="hide" /> */}
                    {/* <CreateAppItem>
                    <Title mb={2}>Credentials</Title>
                    <Grid gap={1}>
                      <TextField
                        variant="outlined"
                        id="applicationPasswordId"
                        type="password"
                        label="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        touched={touched.password}
                        errors={errors.password}
                        disabled={readOnlyForm}
                      />
                      <TextField
                        variant="outlined"
                        id="applicationConfirmPasswordId"
                        type="password"
                        label="Confirm password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        touched={touched.confirmPassword}
                        errors={errors.confirmPassword}
                        disabled={readOnlyForm}
                      />
                    </Grid>
                  </CreateAppItem> */}
                  </FormWrapper>
                  <FormAction>
                    {applicationOprationsButton(values, errors)}
                  </FormAction>
                </CreateAppForm>
              </div>
            );
          }}
        </Formik>
      )}
    </section>
  );
};

ApplicationForm.propTypes = {
  values: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    contactNo: PropTypes.string,
    password: PropTypes.string,
    role: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  errors: PropTypes.bool,
};
// ApplicationForm.defaultProps = {
//   values: {
//     firstname: '',
//     lastname: '',
//     email: '',
//     contactNo: '',
//     password: '',
//     role: '',
//     confirmPassword: '',
//   },
//   handleChange: () => { },
//   touched: false,
//   errors: false,
// };

export default ApplicationForm;
